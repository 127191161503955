<template>
  <div>
    <v-simple-table class="table-padding-2-no-top">
      <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.program_name')"
              :placeholder="$t('labels.program_name')"
              name="name"
              sort-name="name"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 135px">
            <SelectPos
              :label="$t('labels.apply')"
              :placeholder="$t('labels.apply')"
              name="id_pos"
              sort-name="id_pos"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter
              :label="$t('labels.start')"
              :placeholder="$t('labels.start')"
              name="start_time"
              sort-name="start_time"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter
              :label="$t('labels.end')"
              :placeholder="$t('labels.end')"
              name="end_time"
              sort-name="end_time"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.promotion_type')"
              :placeholder="$t('labels.promotion_type')"
              :options="promotionTypeOptions"
              name="type"
              sort-name="type"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 135px">
            <SelectFilter
              :label="$t('labels.is_discount_sale_product')"
              :placeholder="$t('labels.is_discount_sale_product')"
              :options="yesNoOptions"
              name="is_discount_sale_product"
              sort-name="is_discount_sale_product"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.creator_name')"
              :placeholder="$t('labels.creator_name')"
              name="identity_name"
              sort-name="identity_name"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.time')"
              :placeholder="$t('labels.time')"
              :options="programTimeOptions"
              name="type"
              sort-name="type"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.status')"
              :placeholder="$t('labels.status')"
              :options="yesNoOptions"
              name="status"
              sort-name="status"
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th class="text-center">
            <v-btn small color="success" @click="showDialogAddPromotion">
              {{ $t("labels.add") }}
            </v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-for="(item, index) in items"
          :key="`${index}_${item.id}`"
        >
          <td>
            <span
              class="primary--text cursor-pointer text-decoration-underline"
              @click="showDialogDetailPromotion(item)"
            >
              {{ item.name }}
            </span>
          </td>
          <td>
            <div v-html="item.accepted_pos" style="white-space: pre"></div>
          </td>
          <td>{{ formatDateTime(item.start_time) }}</td>
          <td>{{ formatDateTime(item.end_time) }}</td>
          <td>
            <div :class="item.promotion_type_color">
              {{ item.promotion_type_text }}
            </div>
          </td>
          <td>{{ item.is_discount_sale_product ? "Yes" : "No" }}</td>
          <td>{{ item.identity_name }}</td>
          <td>
            <div v-if="item.status" :class="item.program_color">
              {{ item.program_text }}
            </div>
          </td>
          <td>{{ item.status ? "Yes" : "No" }}</td>
          <td>
            <v-btn
              v-if="item.status && item.type"
              color="error"
              x-small
              @click="promotionCancel(item)"
            >
              {{ $t("labels.cancel") }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="text-center mt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="4"
      ></v-pagination>
    </div>

    <v-dialog v-model="addPromotionDialog" persistent max-width="1200px">
      <PromotionCreate
        v-if="addPromotionDialog"
        @close="hideDialogAddPromotion"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="detailPromotionDialog" persistent max-width="1200px">
      <PromotionDetail
        v-if="detailPromotionDialog"
        :item="detail"
        @close="hideDialogDetailPromotion"
      />
    </v-dialog>
  </div>
</template>

<script>
import { YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "PromotionConfig",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectPos: () => import("@/components/table/SelectPos"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    PromotionCreate: () => import("@/components/pos/PromotionCreate"),
    PromotionDetail: () => import("@/components/pos/PromotionDetail"),
  },
  data: () => ({
    items: [],
    filters: {},
    page: 1,
    totalPage: 1,
    isLoading: false,
    addPromotionDialog: false,
    detailPromotionDialog: false,
    detail: {},
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    programTimeOptions() {
      return [
        {
          text: "Đang diễn ra",
          color: "success--text",
          value: 2,
        },
        {
          text: "Sắp diễn ra",
          color: "primary--text",
          value: 1,
        },
        {
          text: "Đã kết thúc",
          color: "error--text",
          value: 0,
        },
      ];
    },
    promotionTypeOptions() {
      return [
        {
          text: "Giá trị hóa đơn",
          color: "pl-2 text-left",
          value: 1,
        },
        {
          text: "Mã SP",
          color: "pl-2 text-left",
          value: 2,
        },
        {
          text: "Combo nhiều mã SP",
          color: "pl-2 text-left",
          value: 3,
        },
        {
          text: "UID",
          color: "pl-2 text-left",
          value: 4,
        },
      ];
    },
    statusOptions() {
      return [
        {
          text: "Hoạt động",
          color: "success--text",
          value: 1,
        },
        {
          text: "Đã hủy",
          color: "error--text",
          value: 0,
        },
      ];
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showDialogAddPromotion() {
      this.addPromotionDialog = true;
    },
    hideDialogAddPromotion() {
      this.addPromotionDialog = false;
    },
    getStatusText(item) {
      return this.statusOptions.find((p) => p.value === item.status);
    },
    getProgramText(item) {
      return this.programTimeOptions.find((p) => p.value === item.type);
    },
    getPromotionTypeText(item) {
      return this.promotionTypeOptions.find(
        (p) => p.value === item.promotion_type
      );
    },
    getAcceptedPos(item) {
      const { poss } = window.me;
      const accepted = item.pos_codes.split("\n");
      const checkAll = [...poss].filter((pos) => !accepted.includes(pos.code));
      return checkAll.length === 0 ? this.$t("labels.all_pos") : item.pos_codes;
    },
    async promotionCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_program"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-cancel-promotion", {
          id: item.id,
        });
        this.isLoading = false;
        this.getList();
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-get-promotion", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((r) => {
            r.accepted_pos = this.getAcceptedPos(r);

            const promotionType = this.getPromotionTypeText(r);
            r.promotion_type_text = promotionType.text;
            r.promotion_type_color = promotionType.color;

            const program = this.getProgramText(r);
            r.program_text = program.text;
            r.program_color = program.color;

            return r;
          });
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDialogDetailPromotion(item) {
      this.detail = { ...item };
      this.detailPromotionDialog = true;
    },
    hideDialogDetailPromotion() {
      this.detailPromotionDialog = false;
    },
  },
};
</script>

<style scoped></style>
